/* eslint-disable react/prop-types */
import * as React from "react";
import propTypes from "prop-types";
import { navLinks } from "../../constants/navigation-links";
import * as styles from "./styles.module.css";

const Navigator = (props) => {
  const { currentPage } = props;
  const navigator = navLinks.map((link, idx) => (
    <>
      <a
        href={link.ref}
        className={currentPage === idx ? styles.highlightLink : styles.link}
      >
        {link.name}
      </a>
      <br />
    </>
  ));

  return <div className={styles.navigator}>{navigator}</div>;
};

Navigator.propTypes = {
  currentPage: propTypes.number.isRequired,
};

export default Navigator;
