/* eslint-disable react/jsx-no-target-blank */
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./styles.module.css";
import { socialLinks } from "../../constants/social-links";

const Social = () => {
  const socials = socialLinks.map((link) => (
    <>
      <a href={link.link} target="_blank">
        <FontAwesomeIcon
          icon={link.icon}
          title={link.title}
          className={styles.socialIcon}
        />
      </a>
    </>
  ));

  return <div className={styles.social}>{socials}</div>;
};

export default Social;
