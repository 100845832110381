/* eslint-disable react/jsx-curly-brace-presence */
import * as React from "react";
import Typewriter from "typewriter-effect";
import * as styles from "./styles.module.css";
import "../../styles/cursor-animation.css";

const Me = () => (
  <div className={styles.intro}>
    <div className={styles.name}>{`Hi, I'm Rohini`}</div>
    <div className={styles.tagline}>
      <Typewriter
        options={{
          strings: "Software Developer and Artist ",
          cursor: "▋",
          cursorClassName: "blinking-cursor",
          autoStart: true,
          delay: 70,
        }}
      />
    </div>
  </div>
);

export default Me;
