import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import Me from "../../components/me";
import Social from "../../components/social";
import Navigator from "../../components/navigator";
import * as styles from "./styles.module.css";
import "../../styles/cursor-animation.css";

const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const refs = [React.createRef(), React.createRef(), React.createRef()];

  const onScroll = () => {
    setCurrentPage(
      refs
        .map(
          (ref) => ref.current.getBoundingClientRect().bottom > window.scrollY
        )
        .indexOf(true)
    );
  };

  return (
    <div onScroll={onScroll} className={styles.root}>
      <Helmet>
        <meta charset="utf-8" />
        <title>Rohini Senthil</title>
      </Helmet>

      <div
        ref={refs[0]}
        className={classnames(styles.page, styles.page1)}
        id="me"
      >
        <Me />
      </div>

      <div
        ref={refs[1]}
        className={classnames(styles.page, styles.page2)}
        id="projects"
      >
        projects
      </div>
      <div
        ref={refs[2]}
        className={classnames(styles.page, styles.page3)}
        id="blog"
      >
        blog
      </div>

      <Social />
      <Navigator currentPage={currentPage} />
    </div>
  );
};

export default LandingPage;
